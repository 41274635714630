import { observer } from "mobx-react";
import React from "react";
import QuestHeader from "../components/quest/QuestHeader";
import SweepWidgetView from "../components/quest/SweepWidgetView";

const QuestPage = observer(() => {
	return (
		<>
			<QuestHeader/>
			<SweepWidgetView/>
		</>
	);
});

export default QuestPage;