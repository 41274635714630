import { Link } from "gatsby";
import React from "react";
import linkImg from "../../images/tribalBooks_logo.png";
import Styles from "./QuestHeader.module.scss"

const QuestHeader = () => (
	<header className={Styles.header}>
		<Link to="/">
			<img className={Styles.image} src={linkImg} alt="logo image"/>
		</Link>
	</header>
);

export default QuestHeader;
