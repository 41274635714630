import React from "react";
import useScript from "../../hooks/useScript";

const SweepWidgetView = () => {
	useScript("https://sweepwidget.com/w/j/w_init.js");

	return (
		<div id="21272-qaz2vsmx" className="sw_container"/>
	);
};

export default SweepWidgetView;